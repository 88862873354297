
import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { signInUser } from '../authTokenConfig';

const FormItem = Form.Item;

class SignInScreen extends React.Component {
  state={
    loading: false
  }
  submitForm(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true})
        const { signInUser } = this.props;
        signInUser({ email: values.userName, password: values.password }).then(r => {
          console.log('signed in', r);
          this.setState({loading: false})
          this.props.history.push('/chat');
        }).catch(e => {
          this.setState({loading: false})
          console.log(e);
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='sign-in auth-screen'>
        <Row>
          <Col md={10} className='content'>
            <div className='logo'>
                <img src={require("../../assets/images/logo.png")}/>
                <h1> Build Bots with ease </h1>
            </div>
            <Form onSubmit={e => this.submitForm(e)} className='form' layout='vertical'>
            <h3>Welcome! Please login to your account.</h3>
            <FormItem >
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Username' />
              )}
            </FormItem>
            <FormItem >
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder='Password' />
              )}
            </FormItem>
            <FormItem>
            <Button loading={this.state.loading} htmlType='submit' className='submit'>
              Sign in
            </Button>      
            </FormItem>
          </Form>
          </Col>
          <Col md={14}>
            <div className=''>
              <img width='100%' src={require("../../assets/images/homeBg.png")}/>
            </div>
          </Col>
        </Row>
       
      </div>
    );
  }

}

const WrappedNormalLoginForm = Form.create()(SignInScreen);

export default connect(null,{ signInUser },)(withRouter(WrappedNormalLoginForm));
