import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Spin, Icon } from 'antd';

import configureStore from './app/store/configureStore';
import { verifyCredentials } from './app/authTokenConfig';
import Root from './Root';

import 'antd/dist/antd.css';
import './index.css';

const initialState = {
  reduxTokenAuth: {
    currentUser: {
      isSignedIn: false,
      attributes: {
        name: null,
        image: null,
        email: null
      },
    },
  }
};

const { store, persistor } = configureStore(initialState);
verifyCredentials(store);
console.log('store',store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} size='large' />} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
