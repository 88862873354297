import * as React from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router';
import { createBrowserHistory } from 'history';
import { generateRequireSignInWrapper } from 'redux-token-auth';
import SignIn from './SignIn';
import Home from './Chat';
import { Layout } from 'antd';

const { Content } = Layout;

const requireSignIn = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: '/signin',
});

const BACKGROUND_COLOR = '#fff';
const history = createBrowserHistory({});

class App extends React.Component {

  render() {
    console.log(this.props.isSignedIn)
    return (
      <Router history={history}>
        <Layout className='layout'>
          <Content style={{ padding: '0 0 0 50px', background: BACKGROUND_COLOR, margin: '0 0 0 60px' }}>
            <div style={{ background: BACKGROUND_COLOR, height: 'calc(100vh - 64px)' }}>
              <Route path='/signin' component={SignIn} />
              <Route exact path='/chat/:id' component={Home} />
              <Route exact path='/chat' component={requireSignIn(Home)} />
              <Route exact path='/' component={requireSignIn(Home)} />
              
            </div>
          </Content>
        </Layout>
      </Router>
    );
  }
}


function mapStateToProps(state) {
  return {
    isSignedIn: state.reduxTokenAuth.currentUser.isSignedIn,
  };
}

export default connect(mapStateToProps)(App);
