import * as React from 'react';

import {connect} from 'react-redux';
import { Spin, Icon } from 'antd';
import App from './app/screens/App';


class Root extends React.Component {

  render() {
      const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />;
      return (
        !this.props.isAttempted ? <Spin indicator={antIcon} size='large' /> : <App />
      );
  }
}

function mapStateToProps(state) {
  return {
      isAttempted: state.reduxTokenAuth.currentUser.hasVerificationBeenAttempted
  };
}

export default connect(mapStateToProps,null)(Root);
