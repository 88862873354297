import { api_url } from './env';
import axios from 'axios';

export class API {
  static sendMessage(projectId, text, debug_mode, language) {
    return axios.post(api_url + '/projects/' + projectId + `/chatbot`, {
      project_id: projectId,
      text,
      debug_mode,
      language,
    });
  }

  static getProjects(userId) {
    return axios.get(api_url + `/users/${userId}/projects/list`)
  }
}