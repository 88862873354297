import * as React from 'react';
import { connect } from 'react-redux';
import { Select, Button, Typography, message } from 'antd';
import { API } from '../api/api';
import { verifyToken, signOutUser } from '../authTokenConfig';
import * as qs from 'query-string';
const { Option } = Select;
const { Text } = Typography;

class Chat extends React.Component {
  state = {
    options: [],
    messages: [],
    chatText: '',
    projects: [],
    currentProject: null,
    project: ''
  }
  messagesEnd;
  scrollHeight = 400;

  project_id = null;
  debug_mode = true;
  language = 'en';


  componentDidMount() {
    this.project_id = this.props.match.params.id;
    if (this.project_id) {
      this.project_id = Number(this.project_id);
      const parsed = qs.parse(this.props.location.search);
      this.language = parsed['lang'] ? parsed['lang'] : this.language;
      this.debug_mode = false;
      this.setState({ currentProject: this.project_id})
    } else {
      API.getProjects(this.props.user.attributes.id).then(r => {
        this.setState({ projects: r.data['user_projects'] })
        if (r.data['user_projects'].length === 1)
          this.setState({ currentProject: r.data['user_projects'][0].id })
      });
    }
    
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  send() {
    if (!this.state.currentProject) {
      message.warning('You have to select project')
    } else {
      if (this.state.chatText !== '') {
        this.sendMessage(this.state.chatText);
        this.setState({ chatText: '' });
      } else {
        message.warning('messege is empty!')
      }
    }
  }

  sendMessage = (message) => {
    this.setState({
      options: [],
      messages: [...this.state.messages, { sentBy: 'user', data: { text: message } }, { sentBy: 'bot', data: { text: '...' } }]
    });

    API.sendMessage(this.state.currentProject, message, this.debug_mode, this.language).then((r) => {

      if (!this.props.user.isSignedIn) {
        this.props.verifyToken(r.headers).then(r => {
          console.log('signed in', r);

        }).catch(e => {
          console.log('error', e);
        });
      }

      let options = r.data.dialogue && r.data.dialogue.options ? r.data.dialogue.options : [];
      let responses = r.data.dialogue ? r.data.dialogue.responses : [];

      if (r.data.variable) {
        Array.prototype.push.apply(options, r.data.variable.options);
        Array.prototype.push.apply(responses, r.data.variable.responses);
      }
      this.setState({
        options,
        messages: [...this.state.messages.slice(0, -1), ...responses.map(r => { return { sentBy: 'bot', data: r }; })]
      });
    });
  }

  submitForm = (e) => {
    e.preventDefault();
  }

  handleChange = (p) => {
    this.setState({ currentProject: p })
  }

  listOfProjects() {
    if (this.state.projects.length > 0) {
      return <Select
        style={{ minWidth: 500 }}
        defaultValue={this.state.projects.length === 1 ? this.state.projects[0].name : 'Select a project'}
        optionFilterProp="children"
        onChange={this.handleChange}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
        {this.state.projects.map((p, i) => {
          return <Option key={i} value={p.id}>{p.name}</Option>
        })}
      </Select>

    } else {
      return <p style={{ margin: 10 }}>No projects found</p>
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollTop += this.scrollHeight;
  }

  renderMesseges() {
    return <div className="msger-chat" style={{ border: 0, minHeight: this.scrollHeight, maxHeight: this.scrollHeight, overflowX: 'hidden', overflowY: 'auto', }}
      ref={(el) => { this.messagesEnd = el; }}>
      {this.state.messages.map((message, index) => {
        return <div className="" key={index} style={{ padding: 10 }}>
          {message.sentBy === 'bot' ?
            <div className="msg left-msg">
              <img alt=""
                className="msg-img"
                src={require("../../assets/images/optobot_icon.png")}
              />
              <div className="msg-bubble">
                <div className="msg-text">
                  {message.data.text && <span style={{ wordBreak: 'break-word' }}>{message.data.text}</span>}
                  {message.data.video && <a href={message.data.video}>{message.data.video}</a>}
                  {message.data.image && <img alt="" src={message.data.image} style={{ maxWidth: '100%' }} />}
                </div>
              </div>
            </div>
            :
            <div className="msg right-msg">
              <img alt=""
                className="msg-img"
                src={require("../../assets/images/user_icon.png")}
              />

              <div className="msg-bubble">
                <div className="msg-text">
                  {message.data.text && <span style={{ wordBreak: 'break-word' }}>{message.data.text}</span>}
                  {message.data.video && <a href={message.data.video}>{message.data.video}</a>}
                  {message.data.image && <img alt="" src={message.data.image} style={{ maxWidth: '100%' }} />}
                </div>
              </div>
            </div>
          }
        </div>

      })}
      <div className="optionsView">
        {this.state.options.map((option, index) => option.text ?
          <Button style={{ borderColor: '#3B80F7', marginRight: 5 }} shape="round" size='Large' onClick={() => this.sendMessage(option.text)}>
            <Text style={{ color: '#3B80F7' }}>{option.text}</Text>
          </Button> :
          <img alt="" key={index} src={option.image} style={{ maxWidth: '100%' }} onClick={() => this.sendMessage(option.image)} />)
        }
      </div>
    </div>

  }

  _render_header() {
    if (this.project_id) {
      return null;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>
          {this.listOfProjects()}
        </div>
        <div className="logout">
          <button className="ant-btn login-form-button ant-btn-primary" style={{ marginRight: 50 }} onClick={this.props.signOutUser}>Logout</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {this._render_header()}

          <div className='details panel panel-info msger' style={{ display: 'flex', flexDirection: 'column' }}>
            <header className="msger-header">
              <div className="msger-header-title">
                <i className="fas fa-comment-alt"></i> ChatBot {this.state.project}
              </div>
            </header>
            {this.renderMesseges()}
            <form onClick={this.submitForm} className="msger-inputarea">
              <input type="text"
                className="msger-input"
                placeholder="Enter your message..."
                onChange={(e) => this.setState({ chatText: e.target.value })} value={this.state.chatText} />
              <button type="submit" className="msger-send-btn" onClick={() => { this.send() }}>Send</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.reduxTokenAuth.currentUser,
  }
}

export default connect(mapStateToProps, { verifyToken, signOutUser },)(Chat);

