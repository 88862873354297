import {createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducers/index';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['reduxTokenAuth']
}


export default function configureStore(initialState) {
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store = createStore(persistedReducer, initialState, applyMiddleware(ReduxThunk));
  let persistor = persistStore(store)
  if (module.hot) {
    // enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = persistedReducer;
      store.replaceReducer(nextReducer);
    });
  }
  return { store, persistor };
}
